<template>
  <Header />
  <Timer @time="timeReached($event)" />
  <MainBanner />
  <div v-if="this.$store.state.options.generalSettings.bannerSlogan.length" class="banner-slogan">{{ this.$store.state.options.generalSettings.bannerSlogan }}</div>
  <Loader v-if="loading" />
  <transition name="fade">
    <div v-if="games !== null && timeLeft > 0" class="games-area-wrapper">
      <QuizFormat v-if="games.type === 'cricket'" :games="games" />
      <Games v-else :games="games" />
    </div>
    <div v-else-if="games !== null && timeLeft <= 0" class="email-area-wrapper">
      <EmailEntry :timeUp="true" />
    </div>
  </transition>
  <EmailSubOverlay v-if="emailSubmissionOverlay.enableOverlay && showOverlay && timeLeft < 0" v-on:close="showOverlay = false" :emailSubOverlayData="emailSubmissionOverlay" />
</template>

<script>
import { mapGetters } from 'vuex';

import Timer from '@/components/Timer.vue';
import MainBanner from '@/components/MainBanner.vue';
import Games from '@/components/Games.vue';
import QuizFormat from '@/components/QuizFormat.vue';
import EmailEntry from '@/components/EmailEntry.vue';
import EmailSubOverlay from '@/components/EmailSubOverlay.vue';

export default {
  name: 'Picks',
  data() {
    return {
      games: null,
      loading: true,
      timeLeft: 0,
      showOverlay: true,
    };
  },
  components: {
    Timer,
    MainBanner,
    QuizFormat,
    Games,
    EmailEntry,
    EmailSubOverlay,
  },
  methods: {
    async getGames() {
      try {
        const { data } = await this.axios.get(`${process.env.VUE_APP_API}/games/v1/${this.$store.state.options.cc}/${this.$store.state.options.sportType}`);
        this.games = data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    timeReached(event) {
      this.timeLeft = event;
    },
  },
  computed: {
    ...mapGetters([
      'emailSubmissionOverlay',
    ]),
  },
  created() {
    this.getGames();
  },
};
</script>

<style>
  .banner-slogan {
    background: #0e1b38;
    padding: .1875rem .5rem .5rem .5rem;
    font-size: 12px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }
</style>
